import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#872782`,
      marginBottom: `1.45rem`,
      fontSize: `50px`,
    }}
  >
    <div
      style={{
        display: `flex`,
        justifyContent: `space-between`,
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <p style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
        </p>
        <p>
        <Link
        to="/products"
        style={{
          color: `white`,
          textDecoration: `none`,
        }}
        >
          Products
        </Link>
      </p>
        <p>
        <Link
        to="/about"
        style={{
          color: `white`,
          textDecoration: `none`,
        }}
        >
          About
        </Link>
      </p>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `The Chicks Coop`,
}

export default Header
